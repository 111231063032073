<template>
  <div :class="$style.root">
    <div v-if="isEmpty(items)">Нет данных</div>
    <VTimeline v-else dense clipped>
      <VTimelineItem
          v-for="(item, index) in items"
          :key="index"
          :color="index > 0 ? 'grey' : 'primary'"
          class="mb-4"
          small
      >
        <div>
          <strong :class="$style.title">{{ get(item, 'author.companyName') }} </strong>
          <small class="font-weight-light"> {{ get(item, 'dateCreate') }}</small>
        </div>
        <div>
          <span v-if="!!item.prevStatus?.code">
            <s>{{ get(item, 'prevStatus.title') }} </s>
            <VIcon v-text="'mdi-arrow-right-thin'" />
          </span>
          <span v-if="item.newStatus?.code">{{ get(item, 'newStatus.title') }}</span>
        </div>
        <div v-if="item.comment">
          <i>Комментарий:</i>
          {{ get(item, 'comment') }}
        </div>
      </VTimelineItem>
    </VTimeline>
  </div>
</template>

<script>

import {get, isEmpty} from 'lodash-es';

export default {
  name: 'TimeLine',
  methods: { get, isEmpty },
  props: {
    items: { type: Array, default: () => [] }
  },
}
</script>

<style module lang="scss">
.root {
  :global(.v-timeline-item__divider) {
    align-items: start;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 4px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-right: 2px;
}
</style>
