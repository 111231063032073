<template>
  <VDialog max-width="900" scrollable persistent v-model="dialog" @input="handleOpen">
    <template #activator="{ on, attrs }">
      <slot name="button" v-bind="{ on, attrs }"></slot>
    </template>
    <VCard tile>
      <VToolbar flat dark color="primary">
        <VToolbarTitle class="px-2">Комментарии</VToolbarTitle>
        <VSpacer/>
        <VBtn icon dark @click="this.close">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VCardText class="pt-5">
        <VForm @submit.prevent="onSubmit">
          <VTextarea v-model="message" label="Комментарий"  rows="3" outlined auto-grow hide-details ref="input" />
          <div class="mt-3">
            <VBtn color="primary" depressed class="mr-3" :disabled="!message" @click="onSubmit">Отправить</VBtn>
            <VBtn v-if="message" color="secondary" depressed class="mr-3" @click="onReset">Отмена</VBtn>
          </div>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>
</template>
<script>
export default {
  name: 'AccountApplicationDialog',
  data() {
    return {
      dialog: false,
      message: '',
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    onSubmit() {
      this.$emit('submit', this.message);
      this.close();
    },
    onReset() {
      this.message = '';
      this.close();
    },
    handleOpen() {
      setTimeout(() => {
        if (this.dialog) this.$refs.input?.$refs.input.focus()
      }, 0)
    }
  },
};
</script>
